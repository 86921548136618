<template>
    <div id="my_script_page">
        <div class="button_box">
            <!-- // 手写插件的撤退。。。 -->
            <div class="action_btn" v-show="show">
                <div class="btn_item clear" @click="editorClear">
<!--                    <i class="icon iconfont iconclear"></i>-->
                    <img v-if="clearAble" src="../../../public/iconImg/icon_clear_active.png" alt="">
                    <img  v-if="!clearAble" src="../../../public/iconImg/icon_clear.png" alt="">清空
                </div>
<!--                <div class="btn_item undo" @click="editorUndo">-->
<!--&lt;!&ndash;                    <i class="icon iconfont iconundo"></i>&ndash;&gt;-->
<!--                    <img v-if="undoAble" src="../../../public/iconImg/icon_back_active.png" alt="">-->
<!--                    <img  v-if="!undoAble" src="../../../public/iconImg/icon_back.png" alt="">回撤-->
<!--                </div>-->
                <!-- <div class="btn_item redo" @click="editorRedo">
                  <i class="icon iconfont iconredo"></i>
                </div> -->
            </div>
            <!-- 上一空，下一空 -->
            <div class="next_btn">
                <!-- <div class="btn_item next" :disabled="loading" @click="nextOne('empty',false)" v-if="nextType === 1">
                  <i class="icon iconfont iconplay_back_fill-copy"></i>
                </div> -->
                <div class="btn_item next" :disabled="loading" @click="nextOne()" v-if="isAllowSubmit">
                    <!-- <i class="icon iconfont iconplay_forward_fill"></i> -->
                    下一空
                </div>

                <!-- <div class="btn_item next" v-show="!show">
                  <i class="icon iconfont icon-jianpan-shanchu"></i>
                </div>-->
                <div class="btn_item submit" :disabled="loading" @click="submitAnswer()" v-if="!isAllowSubmit">
                    <!-- <i class="icon iconfont icon-submit"></i> -->
                    提交
                </div>
                <!-- 键盘手写的切换 true代表手写 -->
<!--                <div class="btn_item next" v-show="show" @touchstart="switchShow">-->
<!--&lt;!&ndash;                    <i class="icon iconfont iconiconset0282"></i>&ndash;&gt;-->
<!--                    <img src="../../../public/iconImg/icon_keyboard.png" alt="">-->
<!--                </div>-->
<!--                <div class="btn_item next" v-show="!show" @touchstart="switchShow">-->
<!--                    <i class="icon iconfont iconwrite"></i>-->
<!--                </div>-->
                <div class="btn_item switch"  @touchstart="switchShow">
                    <img v-if="show" src="../../../public/iconImg/icon_keyboard.png" alt="">
                    <img v-if="!show" src="../../../public/iconImg/icon_write.png" alt="">{{show?'键盘':'手写'}}
                </div>
            </div>
            <div class="close_btn">
                <div class="btn_item down" @click="editorClose">
<!--                    <i class="icon iconfont icondown"></i>-->
                    <img src="../../../public/iconImg/icon_down_full.png" alt="">
                </div>
            </div>
        </div>
        <!-- 手写控件 -->
        <!--<div class="writing-container" v-show="show" :style="{height:contentH + 'px'}" id="editor"-->
        <!--     touch-action="none" ref="editor" @changed="editorChange" @exported="editorExported">-->
        <!--</div>-->
        <!-- 键盘控件 -->
        <div class="writing-container" v-show="!show" :style="{height:contentH + 'px'}">
            <div class="jianpan" touch-action="none">
                <van-button type="default" v-for="(item,index) in keyNum" :key="index"
                            @touchstart="keyboard(item,$event)"
                            :class="[0,1,2,3,4,8,9,13,14,,18,19,20,22].includes(index)?'blue_btn':''">
                    <!-- :style="index===20?btnPosition:(index===21?btnLeft:(index===22?btnRight:''))" -->
                    <template v-if="item==='icon-jianpan-shanchu'">
<!--                        <i class="icon iconfont iconcaozuo-tuige"></i>-->
                        <img src="../../../public/iconImg/icon_delete.png" alt="">
                    </template>
                    <template v-else-if="item==='down'">
<!--                        <i class="icon iconfont iconback"></i>-->
                        <img src="../../../public/iconImg/icon_arrow_left.png" alt="">
                    </template>
                    <template v-else-if="item==='up'">
<!--                        <i class="icon iconfont iconright"></i>-->
                        <img src="../../../public/iconImg/icon_arrow_right.png" alt="">
                    </template>
                    <template v-else-if="item==='icon-kuohao'">
<!--                        <i class="icon iconfont iconzuokuohao"></i>-->
                        <img src="../../../public/iconImg/icon_brackets_left.png" alt="">
                    </template>
                    <template v-else-if="item==='icon-kuohao1'">
<!--                        <i class="icon iconfont iconyoukuohao"></i>-->
                        <img src="../../../public/iconImg/icon_brackets_right.png" alt="">
                    </template>
                    <template v-else>{{item}}</template>
                </van-button>
            </div>
        </div>
    </div>
</template>

<script>
    // import * as MyScriptJS from 'myscript/dist/myscript.esm.js'
    import iink from 'iink-js/dist/iink.min'
    import {EventBus} from '../../event-bus'
    import 'katex/dist/katex.css'

    export default {
        name: 'myScript',
        props: {
            nextType: Number, //按钮状态，1为下一空，2表示提交
            emptyLatexText:Function,
            emptyCount: {type: Number, default: 1},
            currentEmptyIndex: {type: Number, default: 1},
            setCurrentEmptyIndex: Function,
            studentAnswer : Object,
            inputType : {type: Number, default: 0},
            subjectId : {type: Number, default: 0}
            // isAllowSubmit: {type: Boolean, default: false}
        },
        data() {
            return {
                btnPosition: Object.freeze({
                    position: 'absolute',
                    top: 0,
                    right: 0
                }),
                btnLeft: Object.freeze({
                    position: 'absolute',
                    top: '20%',
                    right: 0
                }),
                btnRight: Object.freeze({
                    position: 'absolute',
                    top: '40%',
                    right: 0
                }),
                keyNum: Object.freeze([
                    'down',
                    'up',
                    '%',
                    '÷',
                    'icon-jianpan-shanchu',
                    7,
                    8,
                    9,
                    '×',
                    'icon-kuohao',
                    4,
                    5,
                    6,
                    '-',
                    'icon-kuohao1',
                    1,
                    2,
                    3,
                    '+',
                    ':',
                    '.',
                    0,
                    '='
                ]),
                timeout: null,
                keymsg: '',
                msg: '',
                editor: null,
                data: '',
                index: 0, //当前空额索引
                contentH: 0, //myScript弹窗高度
                loading: false, //下一步按钮状态
                show: false, // 默认显示键盘
                // isAllowSubmit : false,
                clearAble:true,
                undoAble:true,
                //手写识别结果，配置
                inputTypeConfig:[
                    {'type' : 10,'name' : 'keyboard_10'},
                    {'type' : 11,'name' : 'keyboard_11'},
                    {'type' : 14,'name' : 'keyboard_14'},
                    {'type' : 15,'name' : 'keyboard_15'},
                    {'type' : 16,'name' : 'keyboard_16'},
                    {'type' : 101,'name' : 'english_word'},
                ],

                // isAllowSubmit: true,//是否允许提交，用于判断显示下一空还是现实提交按钮
            }
        },
        created() {

            // eslint-disable-next-line
            // console.log('Created' + this.$refs['editor'])
        },
        mounted() {
            console.log('组件',this.inputType,this.subjectId)
            // this.myScriptInit(this.inputType)
            let winH =
                document.documentElement.clientHeight || document.body.clientHeight
            this.contentH = winH * 0.5
        },
        beforeDestroy() {
            EventBus.$off('clearMyScript')
        },
        watch: {
            //监听触控板清空状态
            isClearMyScript(val) {
                // console.log('isClearMyScript1))______', val);
                if (val) {
                    this.editorClear();
                    this.setMyScriptClearStatus(false);
                }

            },
        },
        activated() {
            EventBus.$on('clearnKeybor', e => {
                this.keymsg = ''
            })
        },
        deactivated() {
            this.$destroy()
        },

        computed: {
            //是否允许提交，用于判断显示下一空还是现实提交按钮
            isAllowSubmit(){
                // console.log('__this.answerList computed__',this.answerList);
                let isAllowSubmit = false;
                if (this.emptyCount === 1){
                    return isAllowSubmit
                }

                for (let emptyIndex in this.studentAnswer) {
                    let str = this.studentAnswer[emptyIndex].replace('`','')
                    if (!str || str == ''){
                        isAllowSubmit = true
                    }
                }
                return isAllowSubmit
            }
        },
        methods: {

            //获取按钮类型
            // getBtnType(answerList) {
            //     for (let item of answerList) {
            //         console.log(item);
            //         if (item === '') {
            //             this.isAllowSubmit = false;
            //             break;
            //         }
            //         this.isAllowSubmit = true;
            //     }
            // },

            //组件初始化
            myScriptInit(inputType = 0) {
                let typeConfigName = undefined;
                if (inputType > 0){
                    this.inputTypeConfig.forEach(item => {
                        if (item.type === inputType){
                            typeConfigName = item.name
                        }
                    })
                }
                console.log(this.subjectId)
                if (this.subjectId === 4){
                    typeConfigName = 'english_word'
                }
                if (this.editor){
                    // this.editor = null
                    // return true
                }

                this.editor = iink.register(this.$refs.editor, {
                    // triggers: {
                    //     exportContent: 'QUIET_PERIOD'
                    // },
                    recognitionParams: {
                        // type: parseInt(subjectId) === 4 ? 'TEXT' : 'MATH',
                        type: 'MATH',
                        protocol: 'WEBSOCKET',
                        // protocol: 'REST',
                        apiVersion: 'V4',
                        server: {
                            scheme: 'https',
                            host: 'ms.yuansiwei.com',
                            applicationKey: '9ed75f48-88b5-4ace-bb98-899d58c6610a',
                            hmacKey: 'fd8b2c78-c644-4268-b6ff-a60daa39399a',
                            websocket: {
                                autoReconnect: true, //是否断线重连
                                maxRetryCount: 50, //最大重连次数
                            //     pingEnabled:true,
                            }
                        },
                        iink: {
                            math: {
                                mimeTypes: ['application/x-latex', 'application/vnd.myscript.jiix'],
                                customGrammarId: typeConfigName
                            },
                            export: {
                                jiix: {
                                    strokes: true
                                }
                            },
                            /*text:{
                                mimeTypes :['text/plain'],
                                smartGuide : false,
                                margin : {
                                    bottom : 130,
                                    top : 130,
                                },
                            }*/
                        },

                    }
                });
                this.editor.theme = {
                    'ink':
                        {
                            'color':'#FFFFFF','-myscript-pen-width':1
                        },
                    '.text':
                        {
                            'font-size':12,
                            'line-height' : 3,
                        },
                    '.greenThickPen':{
                        'color':'#2cb8f3',
                        '-myscript-pen-width':1
                    }
                };
                this.editor.penStyleClasses = 'greenThickPen';
                //手写板错误监听
                this.$refs.editor.addEventListener('error', (evt) => {
                    // debugger
                    // this.$route.editor.resize();
                    let reloadCount = Number(this.$L.myLocal('__reload_count__'));
                    reloadCount ++;
                    this.$L.myLocal('__reload_count__',reloadCount);
                    // window.location.reload();
                    // if(evt.detail && evt.detail.type !== 'close') {
                    //     // document.querySelector('#result').innerText = JSON.stringify(evt.detail);
                    //     console.log('evt.detail', evt.detail);
                    // }
                });
                /*setTimeout(() => {
                    this.$set(this.editor.configuration.recognitionParams.iink.math,'customGrammarId','keyboard_14')
                    // this.editor.configuration.recognitionParams.iink.math.customGrammarId = "keyboard_14"
                }, 1000)*/
            },
            // 键盘的输入
            keyboard(type, e) {
                e.preventDefault()
                e.target.style.background = '#f1f1f1'
                const length = this.keymsg.length
                const funTimer = value => {
                    const timer = setTimeout(() => {
                        e.target.style.background = value
                        clearTimeout(timer)
                    }, 60)
                }
                // 点击的效果，特殊按键和数字键不一样
                switch (type) {
                    case 'down':
                    case 'up':
                    case '%':
                    case '÷':
                    case '×':
                    case '-':
                    case '+':
                    case ':':
                    case '.':
                    case '=':
                    case 'icon-jianpan-shanchu':
                    case 'icon-kuohao':
                    case 'icon-kuohao1':
                        funTimer('var(--funbgcolor)')
                        break
                    default:
                        funTimer('var(--numbgcolor)')
                }
                // 触发数据更新
                const fun = () => {
                    if (this.timeout) clearTimeout(this.timeout)
                    this.timeout = setTimeout(() => {
                        // EventBus.$emit('myScriptChange', this.keymsg)
                        // this.setMyScriptResult(this.keymsg);
                        this.emptyLatexText('`'+this.keymsg+'`')
                        // this.keymsg = ''
                    }, 10)
                    const timer = setTimeout(() => {
                        window.MathJax.Hub.Queue([
                            'Typeset',
                            window.MathJax.Hub,
                            document.getElementById('my_script_page')
                        ])
                        clearTimeout(timer)
                    }, 4)
                }
                if (type === 'icon-jianpan-shanchu') {
                    // 点击删除
                    if (length > 0) {
                        if (this.keymsg.endsWith('% ')) {
                            this.keymsg = this.keymsg.slice(0, length - 3)
                        // } else if (this.keymsg.endsWith('×')) {
                        //     this.keymsg = this.keymsg.slice(0, length - 1)
                        // } else if (this.keymsg.endsWith('÷')) {
                        //     this.keymsg = this.keymsg.slice(0, length - 1)
                        } else if (this.keymsg.endsWith('>')) {
                            this.keymsg = this.keymsg.slice(0, length - 2)
                        } else if (this.keymsg.endsWith('<')) {
                            this.keymsg = this.keymsg.slice(0, length - 2)
                        } else {
                            this.keymsg = this.keymsg.slice(0, length - 1)
                        }
                        // else if (this.keymsg.endsWith('( ')) {
                        //             this.keymsg = this.keymsg.slice(0, length - 7)
                        //           } else if (this.keymsg.endsWith(') ')) {
                        //             this.keymsg = this.keymsg.slice(0, length - 8)
                        //           }
                    }
                    fun()
                } else {
                    // 键盘输入
                    switch (type) {
                        // %号需要转义
                        case '%':
                            this.keymsg += '\\% '
                            break
                        case '×':
                            this.keymsg += '×'
                            break
                        case '÷':
                            this.keymsg += '÷'
                            break
                        case 'up':
                            this.keymsg += ' <'
                            break
                        case 'down':
                            this.keymsg += ' >'
                            break
                        case 'icon-kuohao':
                            // this.keymsg += '\\left( '
                            this.keymsg += '('
                            break
                        case 'icon-kuohao1':
                            // this.keymsg += '\\right) '
                            this.keymsg += ')'
                            break
                        default:
                            this.keymsg += type
                    }
                    fun()
                }
            },
            // 数字键盘和手写输入的切换
            switchShow(e) {
                e.preventDefault()
                this.show = !this.show;
                if (this.show){
                    this.editorClear()
                }
                this.show && this.editor.clear()
                // EventBus.$emit("focusInit", null);
                //  EventBus.$emit("show", this.show);
            },
            editorExported(e) {
                const exports = e.detail.exports
                if (exports && exports['application/x-latex'] !== undefined) {
                    let latex = exports['application/x-latex']

                    this.msg = this.cleanLatex(latex)
                    // EventBus.$emit('myScriptChange', latex)
                    latex = this.latexHandle(latex);
                    this.emptyLatexText("`"+latex+"`")
                    setTimeout(() => {
                        window.MathJax.Hub.Queue([
                            'Typeset',
                            window.MathJax.Hub,
                            document.getElementById('my_script_page')
                        ])
                    }, 10)
                }else if (exports && exports['text/plain']){
                    this.emptyLatexText(exports['text/plain'])
                }
            },
            latexHandle(latex){
                return latex.replace(/</g,'< ');
            },
            editorChange(e) {
                // console.log('editorChange______',e);
                // this.undoElement.disabled = !e.detail.canUndo;
                // this.redoElement.disabled = !e.detail.canRedo;
                // this.clearElement.disabled = e.detail.isEmpty;
            },
            cleanLatex(latexExport) {
                if (typeof latexExport === 'number') {
                    latexExport = latexExport.toString()
                }
                if (latexExport.includes('\\\\')) {
                    const steps = '\\begin{align*}' + latexExport + '\\end{align*}'
                    return steps
                        .replace('\\overrightarrow', '\\vec')
                        .replace('\\begin{aligned}', '')
                        .replace('\\end{aligned}', '')
                        .replace('\\llbracket', '\\lbracket')
                        .replace('\\rrbracket', '\\rbracket')
                        .replace('\\widehat', '\\hat')
                        .replace(new RegExp('(align.{1})', 'g'), 'aligned')
                }
                return latexExport
                    .replace('\\overrightarrow', '\\vec')
                    .replace('\\llbracket', '\\lbracket')
                    .replace('\\rrbracket', '\\rbracket')
                    .replace('\\widehat', '\\hat')
                    .replace(new RegExp('(align.{1})', 'g'), 'aligned')
            },
            editorUndo() {
                this.editor.undo()
            },

            nextOne() {
                if (this.currentEmptyIndex < this.emptyCount){
                    this.setCurrentEmptyIndex(parseInt(this.currentEmptyIndex + 1))
                }else {
                    this.setCurrentEmptyIndex(1)
                }
                return true

                let empty_answer = [];
                for (let i in this.studentAnswer) {
                    let str = this.studentAnswer[i].replace('`','')
                    if (!str || str == ''){
                        empty_answer.push(parseInt(i))
                    }
                }
                if (empty_answer.length > 1){
                    empty_answer.splice(empty_answer.indexOf(this.currentEmptyIndex),1)
                }
                this.setCurrentEmptyIndex(empty_answer[0])

            },

            submitAnswer(){
                this.$parent.topicSubmit();
            },
            editorRedo() {
                this.editor.redo()
            },
            editorClose() {
                this.$parent.myScriptClose(false)
            },

            editorClear() {
                this.keymsg = '';
                if (this.editor.canClear){
                    this.editor.clear()
                }
                this.msg = '';
            },
            undo() {
                this.editor.undo()
            },

            redo() {
                this.editor.redo()
            },

            clear() {
                if (this.editor.canClear){
                    this.editor.clear()
                }
            },

            resize() {
                this.editor.resize()
            },


        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        .btn_item{
            font-size: 18px !important;
            margin: 2px 4px !important;
        }
        .topic_item{
            font-size: 18px !important;
        }
    }
    #my_script_page {
        /*background: var(--tabbackcolor);*/
        background: #f1f1f1;

        .button_box {


            .btn_item {
                padding: 6px 12px;
                color: #fff;
                /*border: 1px solid #ccc;*/
                border-radius: 5px;
                margin: 2px;
                @extend .flex_row_center;
                font-size: 16px;

                // margin-right:4px;
                img{
                    height: 20px;
                    width: 20px;
                    margin-right: 4px;
                }
                &.clear {
                    background: linear-gradient(0deg, #FFA300 0%, #FFC969 100%);
                    &:active{
                        background: linear-gradient(0deg, #FFA300 100%, #FFC969 0%);
                    }

                }

                &.undo {
                    background: linear-gradient(180deg, #FFD201 0%, #F6BF00 100%);
                    &:active{
                        background: linear-gradient(180deg, #FFD201 100%, #F6BF00 0%);
                    }
                }

                &.switch{
                    background: linear-gradient(0deg, #01C265 0%, #47E297 100%);
                    &:active{
                        background: linear-gradient(0deg, #01C265 100%, #47E297 0%);;
                    }
                }

                &.redo {
                    border-color: var(--themecolor);
                    background: var(--themecolor);
                }

                &.down {
                    height: 23px;
                    img{
                        margin-right: 0;
                    }
                    .icon_down{
                        transform: rotate(-180deg);
                    }
                    background: linear-gradient(0deg, #01C265 0%, #47E297 100%);
                    &:active{
                        background: linear-gradient(0deg, #01C265 100%, #47E297 0%);;
                    }
                }

                &.next {
                    color: #fff;
                    background: var(--themecolor);
                    border-color: var(--themecolor);
                }
                &.submit{
                    color: #fff;
                    background: #1cc5f0;
                    border-color: #1cc5f0;
                }
            }

            position: absolute;
            z-index: 21;
            left: 0;
            right: 0;
            @extend .flex_row;
            align-items: center;

            .action_btn {
                // flex: 1;
                @extend .flex_row;
                align-items: center;
                justify-content: flex-start;
            }

            .next_btn {
                flex: 1;
                @extend .flex_row_center;
                justify-content: flex-end;
            }

            .close_btn {
                /*flex: 1;*/
                @extend .flex_row;
                justify-content: flex-end;
            }
        }
    }

    .text {
        font-size: 30mm;
        line-height: 12;
    }
    .textfont-sizeline-height{
        line-height: 150mm;
    }
</style>
<style lang="scss">
    @import url('../../assets/css/myScript.css');

    h1,
    h2 {
        font-weight: normal;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 5px;
    }

    a {
        color: #42b983;
    }

    .writing-container {
        min-height: 300px;
        min-width: 150px;

        .jianpan {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            background: var(--background);
            position: absolute;
            top: 40px;
            width: 100%;
            height: calc(100% - 40px);
            padding: 5px;
            box-sizing: border-box;

            .van-button {
                margin: 0 4px;
                width: 17.8%;
                // height: 80px;
                // height:auto !important;
                max-height: 40px;
                min-height: 25px;
                line-height: 40px;
                font-size: 18px;
                border-radius: 5px;
                text-align: center;
                background: var(--numbgcolor);

                .van-button__text{
                    @extend .flex_row_center;
                    img{
                        height: 20px;
                        width: 20px;
                    }
                }



            }

            .blue_btn {
                background: #2cb8f3;
                color: #ffffff;
            }
        }
    }

    #editor {
        width: 100vw;
        height: 126px;
        /*height: 100% !important;*/
        touch-action: none;
    }
</style>
