<template>
    <div class="testPage" id="mathJax">
        <div class="test_page_content">
        <van-nav-bar
                title="在线测评"
                left-arrow
                :right-text="'反馈'"
                @click-left="onClickLeft"
                @click-right="feedbackToggle"
        />
        <div class="page_content">
            <!-- 做题页面 -->
            <div class="exam_content" :style="{paddingBottom:topicPaddingBottom +'px'}">
                <div class="exam_time">
                   <!-- <van-tabs>
                        <van-tab v-for="(item,key) in allQuestions" :title="item.show_order" :key="key">
                            {{ item.id }}
                        </van-tab>
                    </van-tabs>-->
                    <div class="topic_list" id="topicList">
                        <div class="topic_item_box" id="topic_item_box" :style="{left:topicIndexLeft + 'px'}"
                             @touchmove="topicToucheMove($event)" @touchstart="topicTouchStart($event)">
                            <div class="topic_item"
                                 :id="`topic_item_${index}`"
                                 v-for="(item,index) in allQuestions" :key="index"
                                 :class="{
                                 'correct':item.is_start === 1 && item.total_score_rate === 100,
                                 'error':item.is_start === 1 && item.total_score_rate === 0,
                                 'notAllCorrect':item.is_start === 1 && item.total_score_rate > 0 && item.total_score_rate < 100,
                                 'active':index === currentShowOrderKey
                             }"
                                 @click="topicJump(index)"
                            >{{item.show_order}}
                            </div>
                        </div>
                    </div>
                    <!--<div class="time_item ">
                        <div class="time_icon autoFlip">
                            <i class="iconfont iconshalou1"></i>
                        </div>
                        <div class="time_text">
                            <img src="../../../public/iconImg/icon_timer.png" alt="">
                        </div>
                        <div class="time_text total">123</div>
                    </div>-->
                </div>
                <div class="exam_progress">
                    <div class="progress_bg">
                        <div class="progress_item" :style="{width:progressWidth+'%'}"></div>
                    </div>

                </div>
                <div class="exam_topic_box">
                    <div class="exam_topic">

                        <div class="topic_type" v-if="currentQuestionInfo">
                            {{topicType(currentQuestionInfo.type)}}
                        </div>

                        <div class="topic_text" v-if="currentQuestionInfo && currentQuestionInfo.parent_text !== ''"
                             v-html="currentQuestionInfo && currentQuestionInfo.parent_text">
                        </div>

                        <div v-if="allQuestions[currentShowOrderKey] && allQuestions[currentShowOrderKey].hasOwnProperty('sub_show_order') && allQuestions[currentShowOrderKey].sub_show_order > 0">
                            <span style="font-size: 14px;font-weight: bold;color: #019fe8;">第（{{allQuestions[currentShowOrderKey].sub_show_order}}）问</span>
                        </div>

                        <div class="topic_text" v-if="currentQuestionInfo && currentQuestionInfo.type !== 'empty_spaces'"
                             v-html="currentQuestionInfo && currentQuestionInfo.text">
                        </div>

                        <!-- 填空题，内容空格增加点击事件 -->
                        <div class="topic_text" v-if="currentQuestionInfo && currentQuestionInfo.type === 'empty_spaces'">
<!--                             v-html="emptyTextAddOnClick(currentQuestionInfo.text)">-->

                            <span v-for="(item,key) in currentQuestionInfo.empty_text" :key="key">
                                <span v-if="item.value === '__Space_Mark__'">
                                    <!--<input type="text"  class="check_box_item"-->
                                    <!--        :value="studentEmptyAnswer[item.key]">-->
                                    <click-span-new :current-empty-index="currentEmptyIndex" :index="item.key"
                                                :student-answer="studentEmptyAnswer[item.key]"></click-span-new>
                                </span>
                                <span v-else v-html="item.value"></span>
                            </span>
<!--                            <question ref="question" :html="emptyTextAddOnClick(currentQuestionInfo.text)" :question-info="currentQuestionInfo"></question>-->
<!--                            <span v-html="emptyTextAddOnClick(currentQuestionInfo.text)"></span>-->
                        </div>

                    </div>

                    <!-- 音频播放-->
                    <div class="exam_topic">
                        <span v-if="currentQuestionInfo.questionAudio && !currentQuestionInfo.questionEmptyAudio" class="audio" @click="questionAudio(currentQuestionInfo.questionAudio.file_path)">
                            <van-icon :name=" isPalyAudio ? 'pause-circle-o':'play-circle-o'" />
                        </span>
                        <div v-for="audio in currentQuestionInfo.questionEmptyAudio" :key="audio.question_empty">
<!--                            <span>({{audio.question_empty}})</span>-->
                            <span class="audio" @click="questionAudio(audio.file_path)">
                                <van-icon :name=" isPalyAudio ? 'pause-circle-o':'play-circle-o'" />
                            </span>
                        </div>
                    </div>
                    <div class="exam_options">
                        <div class="exam_options_box" v-if="currentQuestionInfo && currentQuestionInfo.type !== 'empty_spaces'">
                            <!--                    多选-->
                            <van-checkbox-group
                                    v-if="currentQuestionInfo && currentQuestionInfo.type === 'multiple_many'" v-model="studentOptionAnswer">
                                <van-checkbox v-for="(option,index) in currentQuestionInfo.options"
                                              @click="studentOption"
                                              :key="index" :name="option.option">{{option.option}}. <span
                                        v-html="option.text"></span>
                                </van-checkbox>
                            </van-checkbox-group>
                            <!--                    单选-->
                            <van-radio-group
                                    v-if="currentQuestionInfo && currentQuestionInfo.type === 'multiple_one'" v-model="studentOptionAnswer">
                                <van-radio v-for="option in currentQuestionInfo.options"
                                           @click="studentOption"
                                           :key="option.option" :name="option.option">
                                    <span class="options_text index">{{option.option}}</span>
                                    . <span class="options_text" v-html="option.text"></span>
                                </van-radio>
                            </van-radio-group>

                        </div>
                    </div>
                </div>
                <div class="exam_footer" @click="topicIndexPositionChange(currentShowOrderKey)">
                    <div class="footer_item submit" :class="{disabled:btnLoading}" @click="topicSubmit">提交</div>
                    <div class="footer_item draft" @click="drawPageShow = !drawPageShow">草稿</div>
                </div>

            </div>
            <!--弹窗-->
            <div class="popup_box" v-show="drawPageShow || feedbackShow">
<!--                <van-popup v-model="drawPageShow" class="drawPage" :overlay="false">-->
<!--                    <div class="box">-->

<!--                        <draw ref="in-draw-mask" :inRatio="3" :inLineColor="'#000'" :inLineWidth="3"-->
<!--                              :inShadowBlur="3"></draw>-->
<!--                        <div class="btn_area">-->
<!--                            <van-button hairline type="info" size="small" @click="drawDelete">清空</van-button>-->
<!--                            <van-button hairline type="primary" size="small" @click="drawClose">关闭</van-button>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </van-popup>-->
                <van-popup v-model="drawPageShow" class="drawPage" :overlay="false">
                    <div class="box">
                        <div class="user_action">
                            <div class="action_item close" @click="drawClose">
                                <img src="../../../public/iconImg/icon_close.png" alt="关闭"/>
                            </div>
                            <div class="action_item clear" @click="drawDelete">
                                <img src="../../../public/iconImg/icon_clear_active.png" alt="清空"/>
                            </div>
                        </div>


                        <draw ref="in-draw-mask" :inRatio="3" :inLineColor="'#000'" :inLineWidth="3"
                                      :inShadowBlur="3"></draw>
                    </div>
                </van-popup>
                <!-- 反馈 -->
                <feedback :question-id="currentQuestionInfo && Number(currentQuestionInfo.id)" :subject-id="currentSubjectId"
                          v-model="feedbackShow"></feedback>

            </div>
            <!--myScript-->
            <!--                 :style="{ top: (isShowMyScript && (currentTopic && currentTopic.type === 'empty_spaces') ) ? '60%' : '100%', touchAction: 'none' }"-->
            <!--<div class="my_script" v-if="is_show_myscript" id="my_script_box"
                 :style="{ top: (isShowMyScript && (currentQuestionInfo && currentQuestionInfo.type === 'empty_spaces') ) ? '60%' : '100%', touchAction: 'none' }"
                 @touchmove.stop @touchstart.stop>
                <my-script ref="myScript" :empty-latex-text="emptyLatexText" :empty-count="parseInt(currentQuestionInfo.empty_count)" :student-answer="studentEmptyAnswer"
                           :next-type="nextType" :current-empty-index="currentEmptyIndex" :input-type="currentQuestionInfo.input_type_int" :subject-id="parseInt(currentSubjectId)" :set-current-empty-index="setCurrentEmptyIndex"></my-script>
            </div>
-->
        </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue/dist/vue.esm.js'
    import CommApi from '../../api/common'
    // import BeforeTraining from "../../components/training/beforeTraining";
    // import BlankQuestion from "../../components/myScript/blankQuestion";
    import MyScript from "../../components/myScript/myScript";
    import draw from '../../components/indraw/Index'
    import Feedback from "../../components/feedback";
    import ClickSpan from "@/components/myScript/clickSpan";
    import Axios from "axios";
    import ClickSpanNew from '../../components/myScript/clickSpanNew'
    Vue.component('click-span', ClickSpan);

    export default {
        name: "training-page",
        components: {
            ClickSpanNew,
            Feedback,
            MyScript,
            // BlankQuestion,
            draw,
            // ClickSpan,
        },
        data() {
            return {
                testId : 0,

                currentShowOrderKey : 0,
                allQuestions: [],   //所有题目序号
                questions : [],     //所有题目内容
                currentQuestionInfo : {},
                currentEmptyIndex : 1,
                isShowMyScript : false,
                studentEmptyAnswer : {},
                studentOptionAnswer : undefined,
                studentAnswer : {},
                answer: '',
                audio : null,
                audioTime : 0,
                tiemr : null,
                isPalyAudio : false,
                input_type_int : undefined,//手写键盘类型
                is_show_myscript : true,

                topicGroup: [],//题组
                topicIndex: 0,//对应题目组索引，用于显示题目
                topicIndexLeft: 0,//题号做定位
                topicTouchStartLeft: 0,//触点坐标定位
                topicMaxWidth: 27,//题号总外宽度
                topicListWidth: 0, //题目外框列表宽度
                configIndex: 0,//题组配置索引，用于计算进度
                optionsAnswer: [],//选择题题答案列表
                currentOptions: [],//当前题目选项
                progressWidth: 0,//进度条

                //计时器字段
                totalMapName: '__TOTAL_TIME_MAP__',
                totalTime: 0,//总计用时
                totalTimer: null,//总计时器

                singleMapName: '__SINGLE_TIME_MAP__',
                singleTime: 0,//单题用时
                singleTimer: null,//单题计时器


                btnLoading: false,//提交按钮状态
                drawPageShow: false,//是否显示草稿
                nextType: 1, //myScript上进nextType阶按钮类型，，0表示无，1表示下一空，2表示提交
                feedbackShow: false,//是否显示反馈
                currentSubjectId: 0,//当前科目id

                currentExamConfig: null,//当前测试配置
                examStartTimestamp: 0,//开始时间戳


                topicActiveIndex: 0,//当前激活的题目索引
                topicPaddingBottom: 0,//当前题目的下边距，兼容手写框高度变化
            }
        },

        created() {
            this.currentSubjectId = this.$L.myLocal('__CURRENT_SUBJECT_ID__');
        },
        activated() {
            this.testId = this.$route.params.testId;
            this.currentSubjectId = this.$L.myLocal('__CURRENT_SUBJECT_ID__');
            this.initStart()
            setTimeout(() => {
                //初始化题号列表宽度参数
                this.topicListWidth = document.getElementById('topicList').getBoundingClientRect().width;
                this.topicMaxWidth = document.getElementById('topic_item_box').getBoundingClientRect().width;
                console.log('indexTarget', this.topicListWidth,this.topicMaxWidth);
            }, 1000);
            // this.$refs.myScript.myScriptInit(this.currentSubjectId);
        },
        deactivated() {
            this.$destroy()
        },
        mounted() {
            this.$nextTick(() => {
                this.initMathJax();
            })
        },
        watch: {
            currentQuestionInfo(){
                setTimeout(() => {
                    if (window.MathJax) {
                        window.MathJax.Hub.Queue([
                            'Typeset',
                            window.MathJax.Hub,
                        ])
                    }
                }, 100)
            },
            is_show_myscript(val){
                if (val){
                    return true
                }
                this.is_show_myscript = false
                this.$nextTick(()=>{
                    this.is_show_myscript = true
                })
            },
            isShowMyScript(val) {
                if (val) {
                    let winH = document.documentElement.clientHeight || document.body.clientHeight;
                    this.topicPaddingBottom = winH * 0.4 - 56;
                } else {
                    this.topicPaddingBottom = 0;
                }
            },
        },
        computed: {},
        methods: {
            //题目类型
            topicType(type) {
                let qtStr = '';
                switch (type) {
                    case 'multiple_one':
                        qtStr = '[单选题]'
                        break
                    case 'multiple_many':
                        qtStr = '[多选题]'
                        break
                    case 'empty_spaces':
                        qtStr = '[填空题]'
                        break
                }
                return qtStr;
            },
            //点击返回按钮
            onClickLeft(){
                this.$router.push({name: 'Test',});
                // this.$router.go(-1);
            },
            jumpUndo() {
                try {
                    //当前可选序号
                    let currentKey = -1;
                    for (let i = 0, aLen = this.allQuestions.length; i < aLen; i++) {
                        let item = this.allQuestions[i];
                        if (item.is_start === 0) {
                            if (currentKey < 0){
                                currentKey = i;
                            }
                            if (this.currentShowOrderKey < i || (this.currentShowOrderKey === 0 && i === 0)){
                                currentKey = i;
                                break;
                            }
                        }
                    }
                    //跳转题号
                    this.$toast.loading('题目跳转中...');
                    setTimeout(()=>{
                        this.$nextTick(()=>{
                            this.$toast.clear();
                            this.topicJump(currentKey);
                        })
                    },200);
                } catch (e) {
                    console.log('获取题目列表异常:', e);
                }
            },
            //动态调整题号位置
            topicIndexPositionChange(index) {
                if (this.topicMaxWidth > this.topicListWidth) {

                    try {
                        //获取单个题号的宽度

                        let targetItem = document.getElementById('topic_item_box').getBoundingClientRect();
                        // this.topicMaxWidth = tar
                        //单个题号宽度
                        let topicPerWidth = this.topicMaxWidth / this.allQuestions.length;
                        //一半宽度的个数
                        let topicNum = parseInt(this.topicListWidth / topicPerWidth / 2)
                        //保持当前题号都处于中间，前后则不管
                        if (index === 0) {
                            this.topicIndexLeft = 0;
                        } else if (index <= topicNum) {
                            this.topicIndexLeft = 0;
                        } else if (this.allQuestions.length - index <= topicNum * 2) {
                            this.topicIndexLeft = this.topicListWidth - this.topicMaxWidth;
                        } else if (index === this.allQuestions.length) {
                            this.topicIndexLeft = -this.topicMaxWidth + (topicPerWidth * (index - 9));
                        } else {
                            this.topicIndexLeft = -topicPerWidth * (index - 1);
                        }

                    } catch (e) {
                        console.log('当前无法获取单个题号对象:', e);
                    }
                }

            },
            topicTouchStart(e) {
                this.topicTouchStartLeft = e.touches[0].pageX;
            },
            //监听题目滑动
            topicToucheMove(e) {
                // return true
                let diffLeft = (this.topicTouchStartLeft - e.touches[0].pageX) / 2;

                // let tempLeft = this.topicIndexLeft - diffLeft;
                // debugger
                let maxLeft = this.topicMaxWidth - this.topicListWidth;
                if (!maxLeft) {
                    debugger
                }
                // debugger
                // let
                if (this.topicMaxWidth > this.topicListWidth) {
                    this.topicIndexLeft -= diffLeft;
                    if (this.topicIndexLeft > 0) {
                        this.topicIndexLeft = 0;
                    } else if (this.topicIndexLeft < -maxLeft) {
                        this.topicIndexLeft = -maxLeft;
                    }
                }


            },
            //题目校验
            topicVerify() {
                let target = this.topicGroup[this.topicIndex];
                let type = target.type;
                try {
                    let userAnswer = this.allQuestions[this.topicIndex].student_answer;
                    // console.log('userAnswer', userAnswer);
                    switch (type) {
                        case 'empty_spaces':
                            // console.log('empty_spaces answerList:',this.answerList);
                            setTimeout(() => {
                                if (Array.isArray(userAnswer)) {
                                    // this.setAnswerList({index: this.index, value: val});

                                    for (let index in userAnswer) {
                                        this.setAnswerList({
                                            index: Number(index),
                                            value: userAnswer[index]
                                        });
                                    }
                                }
                            }, 500)
                            break;
                        case 'multiple_one':


                            this.optionsAnswer = userAnswer[0];
                            break;
                    }
                } catch (e) {
                    console.log('字段异常：this.allQuestions[this.topicIndex].student_answer.__', e);
                }
            },
            //题目排序校验
            topicSort() {
                let temp = [];
                for (let item of this.topicGroup) {
                    for (let target of this.allQuestions) {
                        if (item.id == target.questionid) {
                            temp.push(target);
                        }
                    }
                }
                this.allQuestions = [];
                for (let index in temp) {
                    this.$set(this.allQuestions, index, temp[index]);
                }
            },
            //题目跳转
            topicJump(index,isInit = false) {
                if (index === this.currentShowOrderKey && !isInit){
                    return true
                }
                this.topicIndex = index;
                this.currentEmptyIndex = 1;
                this.currentShowOrderKey = index;
                this.currentQuestionInfo = this.questions[index]
                this.isShowMyScript = this.currentQuestionInfo && this.currentQuestionInfo.type === 'empty_spaces' ? true : false;
                this.studentEmptyAnswer = this.currentQuestionInfo.studentEmptyAnswer
                this.studentOptionAnswer = this.currentQuestionInfo.studentOptionAnswer
                this.currentQuestionInfo.input_type_int = 0;
                if (typeof this.currentQuestionInfo.input_type === "object" && this.currentQuestionInfo.input_type.length > 0){
                    this.currentQuestionInfo.input_type.forEach(item => {
                        if ([10,11,14,15,16].indexOf(item) > -1){
                            this.currentQuestionInfo.input_type_int = item
                        }
                    })
                }
                //英语是101
                if (this.currentQuestionInfo.input_type_int && this.input_type_int !== this.currentQuestionInfo.input_type_int){
                    this.input_type_int = this.currentQuestionInfo.input_type_int
                    this.is_show_myscript = false
                }
                // this.$refs.myScript.myScriptInit(this.currentSubjectId,this.currentQuestionInfo.input_type_int)
                // this.$refs.myScript.customGrammar(this.currentSubjectId,this.currentQuestionInfo.input_type_int)
                this.topicIndexPositionChange(index)
                // this.$refs.myScript.editorClear()
            },
            //切换当前题目空格
            setCurrentEmptyIndex(index){
                if (this.isShowMyScript === false){
                    this.isShowMyScript = true
                }
                if (index === this.currentEmptyIndex){
                    return true
                }
                this.currentEmptyIndex = index < 1 ? 1 : index
                // this.$refs.myScript.editorClear()
                console.log('setCurrentEmptyIndex',index)
            },
            //关闭填空手写组件
            myScriptClose(status){
                if (status === this.isShowMyScript){
                    return true
                }
                this.isShowMyScript = status
            },
            //初始化数学公式
            initMathJax() {
                setTimeout(() => {
                    if (window.MathJax) {
                        window.MathJax.Hub.Queue([
                            'Typeset',
                            window.MathJax.Hub,
                        ])
                    }
                }, 300)
            },
            //反馈快捷选项点击处理函数
            feedbackClick(item) {
                console.log(item);
            },
            //提交反馈
            feedbackSubmit() {

            },
            //画板清空
            drawDelete() {
                this.$refs['in-draw-mask'].inDeleteCanvas()
            },
            //画板关闭
            drawClose() {
                this.$refs['in-draw-mask'].inDeleteCanvas()
                this.drawPageShow = false
            },
            //跳转分析页面
            goAnalyse() {
                // this.$A.removeMap(this.totalMapName, this.planInfo.exam_id);
                this.$router.push({
                    name: 'titleList',
                    query: {testId: this.$route.params.testId, correctable: "corrected"}
                })
                /*this.$toast.loading({
                    message: '答题完毕\n答案分析中...',
                    forbidClick: true,
                    duration: 2000
                })*/
                //清空当前专题计时器Map
                // this.$A.removeMap(this.totalMapName, this.planInfo.exam_id);
                //跳转分析页面
                // this.$router.replace({path: `/analyse/${this.planInfo.id}/${this.planInfo.exam_id}/${this.treeId}`})
                // console.log('跳转分析页面');
            },
            studentOption(){
                // this.currentQuestionInfo.studentOptionAnswer
                // this.$set(this.questions[this.currentShowOrderKey].studentOptionAnswer)
                console.log('this.studentOptionAnswer:',this.studentOptionAnswer)
                this.questions[this.currentShowOrderKey].studentOptionAnswer = this.studentOptionAnswer
            },
            //题目跳转
            topicSubmit() {
                let currentInfo = this.allQuestions[this.currentShowOrderKey];
                // console.log('currentInfo', currentInfo);
                // debugger
                if (currentInfo.is_start) {
                    this.$toast('当前题目已提交，自动跳转下一个未做题');
                    //寻找下一个未做的题进行跳转
                    /*for (let i = 0, aLen = this.allQuestions.length; i < aLen; i++) {
                        let target = this.allQuestions[i];
                        if (target.is_start === 0) {
                            setTimeout(() => {
                                this.topicIndex = i;
                            }, 500)

                            break;
                        }

                    }*/
                    return false;
                }

                // this.setIsNextTopicStatus(false);
                if (this.currentQuestionInfo && this.currentQuestionInfo.type === 'empty_spaces') {
                    let hasEmptyAnswer = false;
                    for (let i in this.studentEmptyAnswer) {
                        let str = this.studentEmptyAnswer[i].replace('`','')
                        if (!str || str == '') {
                            hasEmptyAnswer = true;
                            break;
                        }
                    }
                    // console.log('hasEmptyAnswer', hasEmptyAnswer);
                    if (hasEmptyAnswer) {
                        this.$toast('您还有未填的空哦~');

                        return false;
                    }
                }

                if (this.currentQuestionInfo && this.currentQuestionInfo.type !== 'empty_spaces') {
                    if (!this.currentQuestionInfo.studentOptionAnswer) {
                        this.$toast('请选择您的答案~');
                        return false;
                    }
                }

                // return true
                //提交答案
                // let answerText = this.answerList.join(',').replace(new RegExp('\\','g'),"\\\\");
                // console.log('answerText', answerText);
                // return false;
                let startTimestamp = 1;
                /*try {
                    currentQuestionInfo = this.planInfo.allQuestions[this.topicIndex];
                    // console.log('currentQuestionInfo', currentQuestionInfo);

                    partitionId = currentQuestionInfo.partitionid;
                    showOrder = currentQuestionInfo.show_order;
                } catch (e) {
                    currentQuestionInfo = 0;
                }*/
                // try {
                    // startTimestamp = JSON.parse(this.$L.myLocal('__TOTAL_TIME_MAP__'))[`__TEST_ID_${this.$route.params.testId}__`];
                // } catch (e) {
                //     console.log(e);
                // }

                let params = {
                    // answer: this.answerList.join(','),
                    testId: this.$route.params.testId,
                    partitionId: this.currentQuestionInfo.partition_id,
                    questionId: this.currentQuestionInfo.id,
                    showOrder: this.currentQuestionInfo.show_order,
                }
                if (startTimestamp) params.startTime = startTimestamp;

                if (this.currentQuestionInfo && this.currentQuestionInfo.type === 'empty_spaces') {
                    // params.answer = this.answerList.join(',');
                    console.log('this.studentEmptyAnswer',this.studentEmptyAnswer)
                    params.answer = []
                    for (let i in this.studentEmptyAnswer) {
                        params.answer.push(this.studentEmptyAnswer[i])
                    }
                    // params.answer = [...this.studentEmptyAnswer];
                } else {
                    // console.log(this.optionsAnswerList);
                    if (typeof (this.currentQuestionInfo.studentOptionAnswer) === 'string'){
                        params.answer = [this.currentQuestionInfo.studentOptionAnswer]
                    }else {
                        params.answer = this.currentQuestionInfo.studentOptionAnswer
                    }
                }
                this.$toast.loading({
                    message: '正在提交...',
                    forbidClick: true,
                    duration: 0
                })
                console.log('提交参数', params);
                CommApi.postAnswer(params).then(res => {
                    this.$toast.clear();
                    if (res.success) {
                        //将当前已做题目进行标注变色
                        console.log(this.questions[this.currentShowOrderKey]);
                        this.$set(this.allQuestions[this.currentShowOrderKey], 'is_start', 1);
                        this.$set(this.allQuestions[this.currentShowOrderKey], 'total_score_rate', parseInt(res.data.total_score_rate));

                        this.currentEmptyIndex = 1;
                        let result = res.data;
                        if (result.isFinish) {
                            //结束测试，跳转分析页面
                            this.goAnalyse();
                        } else {
                            // if (result.is_next_config) {
                            //     // debugger
                            //     //进行下一个配置
                            //     this.configIndex++;
                            //     this.currentExamConfig = this.planInfo.exam_config[this.configIndex];
                            //
                            //     // this.topicGroup = this.planInfo.exam_config[this.configIndex];
                            //     // console.log('this.topicGroup ', this.topicGroup, 'this.configIndex', this.configIndex);
                            //     let params = {
                            //         plan_id: this.planId,
                            //         exam_id: this.planInfo.exam_id,
                            //         exam_config_id: this.planInfo.exam_config[this.configIndex].id
                            //     };
                            //     this.getQuestion(params)
                            // } else {
                            //进行同一个配置中的下一题
                            //计算进度条增量
                            let perWidth = (1 / this.questions.length) * 100;
                            // baseWidth = (1 / this.topicGroup.length) * perWidth;
                            this.progressWidth += perWidth;
                            this.jumpUndo();
                        }
                    } else if (res.code === -1) {
                        this.$toast('身份失效，跳转中...');
                        this.$router.replace({path: '/login'});
                    } else {
                        if (res.code === 100206) {
                            //专题配置已完成，判断当前是否是最后一个专题，如果是，则直接跳转分析
                            // console.log('100206____',this.planInfo.exam_config.length, this.configIndex);
                            // debugger
                            if ((this.configIndex + 1) === this.planInfo.exam_config.length) {
                                this.goAnalyse();
                            }
                        } else if (res.code === 10003) {
                            console.log(this.topicIndex, this.topicGroup.length);
                            // debugger
                            if (this.topicIndex < this.topicGroup.length - 1) {
                                // debugger
                                this.topicIndex++;
                                console.log('this.topicIndex', this.topicIndex);
                                //开始单题计时
                                this.timerStart('single');
                            } else {
                                this.goAnalyse();
                            }
                        } else {
                            this.$toast('系统异常，请稍后再试');
                        }

                    }
                })

            },
            //初始化计时器
            initTimer() {
                //全时
                this.timerStart('total');
                //分时
                this.timerStart('single');
                // setTimeout(()=>{
                //     this.$A.removeMap(this.singleMapName,this.currentTopic.id);
                // },3000)
            },
            /**
             * 专题计时器启动
             * @param type string 计时器类型，可选total 和single 表示总计时期和单题计时器
             */
            timerStart(type) {
                // debugger
                let MapName, MapKey, timeName, timerName;
                switch (type) {
                    case 'total':
                        MapName = this.totalMapName;
                        MapKey = `__TEST_ID_${this.$route.params.testId}__`;
                        timeName = 'totalTime';
                        timerName = 'totalTimer';
                        break;
                    case 'single':
                        // debugger
                        MapName = this.singleMapName;
                        MapKey = this.currentTopic.id;
                        timeName = 'singleTime';
                        timerName = 'singleTimer';
                        break;
                }
                let startTime = this.$A.getMap(MapName, MapKey);
                let currentTime = this.$A.getTimestamp();
                if (startTime) {
                    this[timeName] = currentTime - startTime;
                } else {
                    this.$A.saveMap(MapName, MapKey);
                    this[timeName] = 0;
                }


                if (this[timerName]) clearInterval(this[timerName]);
                this[timerName] = setInterval(() => {
                    this[timeName]++;
                }, 1000)
            },

            feedbackToggle() {
                this.feedbackShow = true;
                this.currentSubjectId = this.$L.myLocal('__CURRENT_SUBJECT_ID__');

            },
            //初始化开始练习
            initStart() {
                // debugger
                if (this.testId) {
                    CommApi.getExamQuestion({testId : this.testId}).then(res => {
                        if (res.success) {
                            // this.allQuestions = res.data.allQuestions;
                            this.questions = res.data.questions;
                            let isFinance = true;
                            //全部已做，跳到主页
                            res.data.allQuestions.forEach(item => {
                                if (!item.is_start || item.is_start === 0){
                                    isFinance = false
                                }
                                //处理小题题号
                                if (typeof (item.show_order) === 'string'){
                                    let show_order = item.show_order.split('-')
                                    item.sub_show_order = show_order[1];
                                }else {
                                    item.sub_show_order = 0;
                                }
                                this.allQuestions.push(item)
                            })
                            if (isFinance){
                                this.$router.push({path:'/Test'});
                                return true
                            }

                            for (let i in this.questions) {
                                this.questions[i].studentEmptyAnswer = {}
                                this.questions[i].is_start = false
                                this.questions[i].empty_text = []
                                let studentAnswer = '';
                                if (this.allQuestions[i].student_answer && this.allQuestions[i].student_answer.length > 0){
                                    studentAnswer = this.allQuestions[i].student_answer;
                                }
                                if (this.questions[i].type === 'empty_spaces'){
                                    // console.log('studentAnswer',studentAnswer,typeof (studentAnswer),parseInt(this.questions[i].empty_count))
                                    this.questions[i].empty_text = this.emptyTextAddOnClick(this.questions[i].text)

                                    for (let j = 1; j <= parseInt(this.questions[i].empty_count) ; j++) {
                                        if (typeof (studentAnswer) === 'object' && (studentAnswer[j-1] || studentAnswer[j-1] != undefined)){
                                            this.$set(this.questions[i].studentEmptyAnswer,j,studentAnswer[j-1])
                                        }else {
                                            this.$set(this.questions[i].studentEmptyAnswer,j,"")
                                        }
                                    }
                                }else {
                                    this.questions[i].studentOptionAnswer = this.questions[i].type === 'multiple_one' ? '' : [];
                                    if (studentAnswer !== ''){
                                        if (this.questions[i].type === 'multiple_one'){
                                            this.questions[i].studentOptionAnswer = studentAnswer[0]
                                        }else {
                                            this.questions[i].studentOptionAnswer = studentAnswer
                                        }
                                    }
                                }

                            }
                            this.topicJump(this.currentShowOrderKey, true)
                            // this.currentQuestionInfo = this.questions[this.currentShowOrderKey]
                            // this.studentEmptyAnswer = this.currentQuestionInfo.studentEmptyAnswer
                            // this.studentOptionAnswer = this.currentQuestionInfo.studentOptionAnswer
                            //初始化计时器
                            // this.initTimer();

                            //判断当前未做题并跳转
                            this.jumpUndo();

                        } else if (res.code === -1) {
                            this.$router.replace({path: '/login'})
                        } else {
                            this.$toast('后台数据异常，请稍后再试')
                        }
                    })
                }
            },

            //手写组件返回手写内容
            emptyLatexText(text){
                this.$set(this.currentQuestionInfo.studentEmptyAnswer,this.currentEmptyIndex,text)
                this.$set(this.studentEmptyAnswer,this.currentEmptyIndex,text)
            },
            //空格text 查找空格，并增加点击事件
            emptyTextAddOnClick(text){
                let topicParts = [];//将题目按空格分段
                let ruleNumber = 0;
                if (text.indexOf('<span class="yswEmptys">') !== -1 || text.indexOf("<span class='yswEmptys'>") !== -1) {
                    ruleNumber = 1;
                    //常规匹配
                    topicParts = text.replace(/<span class=(.*?)yswEmptys(.*?)>(.*?)<\/span>/g, ',__Space_Mark__,').split((','));
                } else if (text.indexOf('<u>') !== -1) {
                    ruleNumber = 2;
                    //下划线匹配
                    topicParts = text.replace(/<u>(.*?)<\/u>/g,- ',__Space_Mark__,').split((','));
                } else if (text.indexOf('__') !== -1) {
                    ruleNumber = 3;
                    //匹配横线
                    topicParts = text.replace(/_{2,}/g, ',__Space_Mark__,').split((','));
                } else {
                    ruleNumber = 4;
                    //通用匹配
                    try {
                        let emptyCount = Number(this.questions[this.currentShowOrderKey].empty_count);
                        if (emptyCount) {
                            topicParts = text.replace(/_{2,}/g, ',__Space_Mark__,').split((','));
                        }
                    } catch (e) {
                        console.log('获取题目信息异常：get the question info error;', e);
                    }

                }
                let empty_texts = []
                let indexClick = 1;
                topicParts.forEach(item => {
                    if (item === '__Space_Mark__'){
                        empty_texts.push({key: indexClick,value: item})
                        indexClick++;
                    }else {
                        empty_texts.push({key: 0,value: item})
                    }
                })
                console.log('ruleNumber:',ruleNumber)
                return empty_texts;

            },

            //题目音频/视频 地址
            questionAudio(path){
                path = Axios.defaults.baseURL+path
                if (!this.isPalyAudio) {
                    this.isPalyAudio = true
                    this.audio = new Audio();
                    this.audio.src = path;
                    let playPromise = this.audio.play();
                    playPromise.then(() => {
                        // 音频加载成功
                        // 音频的播放需要耗时
                        this.audioTime = this.audio.duration
                        this.tiemr = setInterval(() => {
                            this.audioTime--;
                            if (this.audioTime <= 0) {
                                this.audio.pause()
                                this.audio = null
                                this.isPalyAudio = false
                                clearInterval(this.tiemr);
                            }
                        }, 1000);
                    }).catch((e) => {
                        // 音频加载失败
                        console.error(e);
                    });
                }
                // return path;
            }
        }
    }
</script>

<style lang="scss">

    .audio {
        font-size: 35px;
        color: #019FE8;
    }
    .testPage {
        @extend .abs_full_screen;
        .test_page_content{
            @extend .abs_full_screen;
            @extend .flex_column;
        }


        .page_content {
            width: 100%;
            flex: 1;
            /*position: relative;*/
            @extend .flex_column;

            .exam_content {
                /*<!--@extend .abs_full_screen;-->*/
                @extend .flex_column;
                flex: 1;
                padding: 12px;
                .van-radio{
                    @extend .flex_row_center;
                    align-items: flex-start;
                    justify-content: flex-start;


                    &__label{
                        @extend .flex_row_center;
                        justify-content: flex-start;
                        align-items: flex-start;
                    }
                }

                .exam_time {
                    @extend .flex_row_center;
                    align-items: flex-start;
                    padding: 6px 12px;
                    height: 42px;

                    .topic_list {
                        /*flex: 1;*/
                        //width: calc(100% - 100px);
                        width: 100%;
                        margin-right: 0;
                        overflow-x: hidden;
                        white-space: nowrap;
                        height: 30px;
                        overflow-y: hidden;
                        position: relative;

                        .topic_item_box {
                            position: absolute;
                            transition: all .3s ease;

                            .topic_item {
                                display: inline-flex;
                                margin-bottom: 5px;
                                flex-direction: row;
                                align-items: center;
                                justify-content: center;
                                font-size: 14px;
                                font-weight: bold;
                                width: 25px;
                                height: 25px;
                                border-radius: 50%;
                                margin-right: 8px;
                                border: 1px solid #e3f1ff;
                                background: #e3f1ff;
                                color: #333333;

                                &.finished {
                                    border: 1px solid #02c1ae;
                                    color: #02c1ae;

                                    &.active {
                                        background: #02c1ae;
                                        color: #fff;
                                    }
                                }

                                &.correct {
                                    border: 1px solid #c3ffe2;
                                    background: #c3ffe2;
                                    color: #01d06c;
                                }

                                &.error {
                                    border: 1px solid #ffd3d3;
                                    background: #ffd3d3;
                                    color: #ff5565;
                                }

                                &.notAllCorrect {
                                    border: 1px solid #fff3de;
                                    background: #fff3de;
                                    color: #e89401;
                                }

                                &.active {
                                    border: 1px solid #019fe8;
                                    background: #019fe8;
                                    color: #ffffff;
                                }


                            }
                        }
                    }

                    .time_item {
                        /*flex: 1;*/
                        font-size: 14px;
                        @extend .flex_row_center;
                        color: #999;
                        justify-content: flex-start;

                        &:last-child {
                            justify-content: flex-end;
                            min-width: 100px;
                        }

                        .time_icon, .time_text {
                            padding: 3px;
                            @extend .flex_row_center;

                            img{
                                width: 16px;
                                height: 16px;
                            }

                            &.single, &.autoShake {
                                color: #1cc5f0;
                            }

                            &.total, &.autoFlip {
                                color: orangered;
                            }

                            &.autoFlip {
                                animation: autoFlip linear 2s infinite;
                            }

                            &.autoShake {
                                animation: autoShake linear 2s infinite;
                            }

                            @keyframes autoFlip {
                                0% {
                                    transform: rotate(0deg);
                                }
                                20% {
                                    transform: rotate(180deg);
                                }
                                80% {
                                    transform: rotate(180deg);
                                }
                                100% {
                                    transform: rotate(360deg);
                                }
                            }
                            @keyframes autoShake {
                                0% {
                                    transform: translateX(0px);
                                }
                                5% {
                                    transform: translateX(-1px);
                                }
                                10% {
                                    transform: translateX(1px);
                                }
                                15% {
                                    transform: translateX(-1px);
                                }
                                20% {
                                    transform: translateX(0px);
                                }
                                80% {
                                    transform: translateX(0px);
                                }
                                85% {
                                    transform: translateX(-1px);
                                }
                                90% {
                                    transform: translateX(1px);
                                }
                                95% {
                                    transform: translateX(-1px);
                                }
                                100% {
                                    transform: translateX(0px);
                                }

                            }
                        }
                    }
                }

                .exam_progress {
                    /*height: 24px;*/
                    height: 8px;
                    padding: 0 12px;
                    position: relative;

                    .progress_bg {
                        width: 100%;
                        height: 8px;
                        background: #eee;
                        border-radius: 10px;
                        overflow: hidden;

                        .progress_item {
                            width: 90%;
                            background: var(--selfbg);
                            height: 100%;
                            border-radius: 10px;
                            position: relative;
                            overflow: hidden;
                            transition: width .2s ease;

                            &:after {
                                content: '';
                                height: 40px;
                                width: 20px;
                                position: absolute;
                                top: -12px;
                                left: 0;
                                transform: rotate(45deg);
                                background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.5) 50%, transparent 100%);
                                animation: streamMove linear 4s infinite;
                            }

                            @keyframes streamMove {
                                0% {
                                    left: -100%;
                                    top: -12px;
                                }
                                20% {
                                    left: 110%;
                                }
                                80% {
                                    left: 110%;
                                    top: -60px;
                                }
                                81% {
                                    left: -100%;
                                    top: -12px;
                                }
                                100% {
                                    left: 110%;
                                    top: -12px;
                                }
                            }
                        }
                    }
                }

                .exam_topic_box {
                    width: 100%;
                    height: 0;
                    flex: 1;
                    overflow: auto;

                    .exam_topic {
                        padding: 12px;
                        text-align: left;
                        font-size: 20px;

                        .topic_type {
                            color: rgba(1, 159, 232, 1);
                            font-size: 16px;
                            font-weight: bold;
                        }

                    }

                    .exam_options {
                        padding: 12px;
                        flex: 1;

                        .options_text {
                            font-size: 20px;
                            text-align: left;
                            /*<!--@extend .flex_row_center;-->*/
                            /*<!--justify-content: flex-start;-->*/
                            margin-left: 12px;

                            &.index {
                                font-weight: bold;
                            }
                        }

                        .van-radio, .van-checkbox {
                            margin: 18px 0;
                        }
                    }
                }

                .exam_footer {
                    margin: 0 -12px;
                    height: 56px;
                    padding: 0;
                    position: relative;
                    @extend .flex_row_center;
                    .footer_item{
                        @extend .flex_row_center;
                        font-size: 16px;
                        height: 100%;
                        color: #fff;
                        &.submit{
                            flex: 2;
                            background: linear-gradient(0deg, #019FE8 0%, #6AD0FF 100%);

                            &:active{
                                background: linear-gradient(0deg, #019FE8 100%, #6AD0FF 0%);
                            }
                            &.disabled{
                                pointer-events: none;
                                background: #EEEEEE;
                                color: rgba(102, 102, 102, 1);
                            }
                        }
                        &.draft{
                            flex: 1;
                            background: linear-gradient(0deg, #01C265 0%, #47E297 100%);
                            &:active{
                                background: linear-gradient(0deg, #01C265 100%, #47E297 0%);
                            }
                        }
                    }
                }
            }

            .popup_box {
                @extend .abs_full_screen;

                .feedbackOverlay {

                    .feedback_options {
                        padding: 12px;

                        .option_item {
                            padding: 3px 6px;
                            border: 1px solid #ccc;
                            border-radius: 5px;
                            font-size: 12px;
                            display: inline-block;
                            margin: 6px;
                            color: #888;

                            &:active {
                                background: #f1f1f1;
                            }
                        }
                    }

                    .van-cell__value.van-field__value {
                        border: 1px solid #888;
                        border-radius: 5px;
                        padding: 6px;
                    }
                }

                .wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    z-index: 24;

                    .van-panel {
                        border-radius: 5px;
                        overflow: auto;
                        width: 90%;
                    }

                    .van-cell {
                        font-size: 14px;
                        line-height: 24px;
                        text-align: center;
                    }

                    .van-field__label {
                        width: 40px;
                        color: #666;
                        padding-right: 12px;
                    }

                    .van-field__word-limit {
                        margin-top: 4px;
                        color: #888;
                        font-size: 12px;
                        line-height: 16px;
                    }

                    .van-field__control {
                        color: #999;
                    }

                    .van-panel__footer {
                        text-align: center;

                        button {
                            background: var(--themecolor);
                            border: none;
                            border-radius: 5px;
                            padding: 6px 12px;
                            height: auto;
                            font-size: 14px;
                            width: 40%;

                            &.cancel_btn {
                                background: none;
                                color: #999;
                                border: 1px solid #999;
                                margin-right: 24px;
                            }
                        }
                    }
                }

                .van-overlay {
                    z-index: 2;
                }
            }

            .drawPage {
                width: 100%;
                height: 100%;
                background-color: rgba(255, 255, 255, 0.6);

                .box {
                    @extend .abs_full_screen;

                    .btn_area {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        background: #fff;
                        @extend .flex_row;
                        align-items: center;

                        button {
                            flex: 1;
                            margin: 12px;
                            height: 48px;
                        }

                        .van-button--small {
                            font-size: 14px;
                            /*border-radius: 5px;*/
                            .van-button__text {
                                line-height: 48px;
                            }
                        }
                    }
                }
            }
            .drawPage {
                width: 100%;
                height: 100%;
                background-color: rgba(255, 255, 255, 0.6);

                .box {
                    @extend .abs_full_screen;

                    .user_action{
                        position: absolute;
                        top: 0;
                        right: 0;
                        z-index: 99;
                        .action_item{
                            position: absolute;
                            @extend .flex_row_center;

                            right: 0;
                            width: 36px;
                            height: 36px;
                            border-bottom-left-radius: 5%;
                            border-top-left-radius: 5%;

                            img{
                                width: 24px;
                                height: 24px;
                            }

                            &.close{
                                top: 24px;
                                background: linear-gradient(-21deg, #FF655C 0%, #FF9890 100%);
                                &:active{
                                    background: linear-gradient(-21deg, #FF655C 100%, #FF9890 0%);
                                }
                            }
                            &.clear{
                                top: 72px;
                                background: linear-gradient(-21deg, #FFD201 0%, #F6BF00 100%);
                                &:active{
                                    background: linear-gradient(-21deg, #FFD201 100%, #F6BF00 0%);
                                }
                            }
                        }
                    }


                }
            }

            .my_script {
                position: fixed;
                top: 100%;
                left: 0;
                right: 0;
                height: calc(40% - 56px);
                /*bottom: 50px;*/
                z-index: 1;
                background: #fff;
                transition: top 0.2s;
            }

            .draft_btn {
                position: fixed;
                right: -5px;
                top: 110px;
                z-index: 1;
                color: #fff;
                font-size: 14px;
                background: #5fb8dd;
                box-shadow: 1px 1px 14px 1px rgba(0, 0, 0, 0.1);
                padding: 7px;
                border-radius: 5px;
                border: 1px solid #f1f1f1;
                @extend .flex_row_center;

                .draft_icon {
                    i.iconfont.icon {
                        font-size: 14px;
                    }
                }

                // .draft_text {
                // }
            }
        }
    }
</style>
