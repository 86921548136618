<template>
    <!--<span class="yswEmptys" :id="'yswEmptys_'+index" -->
    <!--      ref="yswEmptys" :class="currentEmptyIndex === index && isDisabled ? 'active':''"-->
    <!--      @click="spanClick">-->
        <input type="text"
               @input="spanValueChange"
               @change="spanValueChange"
               :ref="'click_span_'+index" class="check_box_item" :id="'cbox_'+index"
               value="">
        <!--<span class="empty_input" id="mathJax_label" :for="'cbox_'+index" v-html="inputValue"></span>-->
    <!--</span>-->
</template>

<script>

export default {
    name: 'clickSpanNew',
    props: {
        index: [String, Number],
        currentEmptyIndex: Number,
        studentAnswer : String,
        testClick:Function,
        isDisabled:{Boolean:false}
    },
    data() {
        return {
            inputValue: '', //输入框的值
        }
    },
    activated() {
        setTimeout(() => {
            // if (window.MathJax) {
            window.MathJax.Hub.Queue([
                'Typeset',
                window.MathJax.Hub,
                document.getElementsByClassName('empty_input')
            ])
            // }
        }, 40)
    },
    watch: {
        /**
         * 监听公共变量变化
         * 1、监听设置值 myScriptResult 当前索引值符合当前空格的时候，myScript回写的值就给当前这个空格赋值
         *
         */
        studentAnswer:{
            // eslint-disable-next-line no-unused-vars
            handler(newValue, oldValue) {
                this.inputValue = newValue
                setTimeout(() => {
                    if (window.MathJax) {
                        window.MathJax.Hub.Queue([
                            'Typeset',
                            window.MathJax.Hub,
                            document.getElementsByClassName('yswEmptys')
                        ])
                    }
                }, 4)
            },
            deep: true
        }
    },
    computed: {
    },
    mounted() {
        this.inputValue = this.studentAnswer
    },
    methods: {
        putAnswer(text){
            this.inputValue = text
        },
        spanClick() {

            if (!this.isDisabled){
                return false
            }
            // this.$parent.setCurrentEmptyIndex(this.index)
        },
        getIndex() {
            // this.$parent.$parent.$parent.setCurrentEmptyIndex(this.index)
            // this.currentEmptyIndex = this.index
            console.log('this.currentEmptyIndex: ', this.currentEmptyIndex)
        },
        // blur
        spanValueChange(e) {
            console.log('spanValueChange', e)
        },
    }
}
</script>

<style lang="scss" scoped>
$spanPandding: 12px;
.yswEmptys {
    min-width: 24px;
    text-align: center;
    line-height: 20px;
    padding: $spanPandding;
    border-bottom: 1px solid #666;
    display: inline-block;
    color: #999;
    position: relative;
    cursor: pointer;
    transition: border-bottom-width 0.2s;
    vertical-align: middle;

    .check_box_item {
        opacity: 0;
        position: absolute;
        top: -$spanPandding;
        left: -$spanPandding;
        width: 100%;
        height: 100%;
    }

    label {
        @extend .flex_row_center;
    }

    .empty_input {
        font-size: 14px;
        background: none;
        border: none;
        text-align: center;
        color: var(--themecolor);
    }

    &.active {
        border-color: var(--themecolor);
        border-bottom-width: 2px;
    }
}
</style>
